import { ProposalStatus } from "../../hooks/useProposalData";
import { ProposalCardProps } from "./ProposalCard.entities";
import styles from "./ProposalCard.module.css";
import BaseText, { FontWeight, TextSize } from "../baseText";
import { ErrorIcon } from "../icons";
import { colour } from "../../global/theme";

const WARNING_ICON_SIZE = 20;

const ProposalCard = (props: ProposalCardProps) => {
  const classList = [styles.proposalCard];
  if (props.isActive) {
    classList.push(styles.isActive);
  }
  const getFormattedDate = () => {
    return new Date(props.dateCreated).toLocaleDateString("en-UK");
  };

  const getIntials = () => {
    return props.creator
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  const renderStatus = () => {
    if (props.status === ProposalStatus.Confirmed) return null;
    return (
      <div className={styles.warningContainer}>
        <ErrorIcon
          height={WARNING_ICON_SIZE}
          width={WARNING_ICON_SIZE}
          backgroundFill={colour.warning}
          lineFill={colour.black}
        />
      </div>
    );
  };

  return (
    <div className={classList.join(" ")} onClick={props.toggleIsActive}>
      <div className={styles.row}>
        <BaseText
          text={props.name}
          size={TextSize.Large}
          fontWeight={FontWeight.Bold}
          className={styles.marginRightAuto}
        />
        {renderStatus()}
        <div className={styles.initials}>
          <BaseText
            text={getIntials()}
            size={TextSize.Mini}
            fontWeight={FontWeight.SemiBold}
            singleLine
          />
        </div>
      </div>
      <BaseText
        text={`Hash: ${props.hashedContents}`}
        size={TextSize.Small}
        className={styles.bottomMargin}
        singleLine
      />
      <div className={styles.row}>
        <BaseText
          text={getFormattedDate()}
          size={TextSize.Small}
          className={styles.marginRightAuto}
        />
        <BaseText
          text={`No. of files: ${props.fileLength}`}
          size={TextSize.Small}
        />
      </div>
    </div>
  );
};

export default ProposalCard;
