import { Proposal } from "../../hooks/useProposalData";
import ProposalCard from "../proposalCard/proposalCard.view";
interface ProposalFeedProps {
  proposals: Proposal[];
  toggleProposalIsActive: (index: number, proposalId: string) => void;
  isActiveOverride: boolean;
}

const ProposalCardFeed = (props: ProposalFeedProps) => {
  return (
    <>
      {props.proposals.map((proposal, index) => (
        <ProposalCard
          id={proposal.id}
          key={proposal.id}
          name={proposal.name}
          creator={proposal.creator}
          isActive={proposal.isActive || props.isActiveOverride}
          toggleIsActive={() =>
            props.toggleProposalIsActive(index, proposal.id)
          }
          dateCreated={proposal.dateCreated}
          hashedContents={proposal.hashedContents}
          fileLength={proposal.files.length}
          status={proposal.status}
        />
      ))}
    </>
  );
};

export default ProposalCardFeed;
